<template>
  <a class="WebsiteLink" target="_blank" :href="cleanedHref">
    <slot>{{ cleanedHref }}</slot>
  </a>
</template>

<script>
export default {
  name: 'WebsiteLink',

  props: {
    href: {
      type: String,
      default: ''
    }
  },

  computed: {
    cleanedHref () {
      const href = this.href

      return href.startsWith('http') ? href : `http://${href}`
    }
  }
}
</script>
